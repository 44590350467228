import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

export default function Home() {
  return (
    <Layout>
      <SEO title="Home" />
      <div
        style={{
          display: `flex`,
          alignItems: `center`,
          justifyContent: `center`,
        }}
      >
        <StaticImage
          src="../images/NieuwenhuisWebDevelopmentLogoComplete.png"
          alt="Nieuwenhuis Web Development B.V. Logo"
          loading="eager"
          placeholder="blurred"
        />
      </div>
    </Layout>
  );
}
